<template>
  <el-container>
    <el-main class="create-shop">
      <div style="text-align: right; margin-bottom: 10px">
        <router-link to="/shop/index">
          <el-button size="small" icon="el-icon-arrow-left">返回</el-button>
        </router-link>
      </div>
      <el-steps :active="active" finish-status="success">
        <el-step title="产品方案"></el-step>
        <el-step title="程序版本"></el-step>
        <el-step title="站点信息"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
      <div class="product" v-if="active == 0">
        <div class="product-item" v-for="(item, index) in caseList" :key="index">
          <div
            class="top"
            :style="{ background: `url(${item.images})no-repeat`, backgroundSize: '100% 100%' }"
          >
            <p class="title">{{ item.name }}</p>
            <p class="desc">{{ item.describe }}</p>
          </div>
          <div class="btm">
            <p class="desc">可免费使用</p>
            <el-button
              type="primary"
              :disabled="!item.is_online"
              @click="next(1, item.id)"
              size="small"
              >{{ item.is_online ? '立即创建' : '即将上线' }}</el-button
            >
          </div>
        </div>
      </div>
      <div class="shop-list" v-else-if="active == 1">
        <div class="shop-list-item" v-for="(item, index) in mealList" :key="index">
          <p class="title">{{ item.name }}</p>
          <p class="func">{{ item.name }}套餐</p>
          <div class="price" data-v-5d2e6e1a="">{{ item.price }}/年</div>
          <div class="now-price" data-v-5d2e6e1a="">限时优惠：{{ item.discount }}元/年</div>
          <el-button type="primary" @click="next(2, item.id)" size="small">立即创建</el-button>
        </div>
      </div>

      <div class="shop-info" v-else-if="active == 2">
        <div class="name">
          <p>站点名称：</p>
          <el-input placeholder="请输入内容" v-model="name"></el-input>
        </div>
        <div class="shop-logo">
          <p>站点logo：</p>
          <el-input :disabled="true" v-model="logo">
            <el-button slot="append" @click="showImgLibrary = !0" icon="el-icon-upload"></el-button>
          </el-input>
        </div>
        <div class="show-img" v-show="logo">
          <img :src="logo" alt="" />
        </div>
        <el-button type="primary" @click="next(3)" size="small">立即创建</el-button>
      </div>
      <p v-else class="success">应用创建成功，{{ time }}秒后跳转至应用列表</p>
    </el-main>
    <ImgLibrary
      v-if="showImgLibrary"
      :url="1"
      @changeStatus="changeStatus"
      :dialogVisible="showImgLibrary"
      @getImg="getImg"
    ></ImgLibrary>
  </el-container>
</template>

<script>
import ImgLibrary from '@/components/imgLibrary'
export default {
  components: {
    ImgLibrary
  },
  data() {
    return {
      time: 5,
      showImgLibrary: !1,
      active: 0,
      caseList: [],
      mealList: [],
      meal_id: '',
      case_id: '',
      logo: '',
      name: ''
    }
  },
  created() {
    this.getCase()
  },
  methods: {
    next(i, id) {
      if (i == 1) {
        this.case_id = id
        //获取套餐
        this.$axios.post(this.$api.site.getMeal,{
          case_id:this.case_id
        }).then((res) => {
          if (res.code == 0) {
            this.mealList = res.result
            this.active = i
          } else {
            this.$message.error(res.msg)
          }
        })
      } else if (i == 2) {
        this.meal_id = id
        this.active = i
      } else {
        //添加站点
        if (!this.name) return this.$message.error('请填写站点名称')
        if (!this.logo) return this.$message.error('请上传站点图片')
        this.$axios
          .post(this.$api.site.addProgram, {
            meal_id: this.meal_id,
            case_id: this.case_id,
            name: this.name,
            logo: this.logo
          })
          .then((res) => {
            if (res.code == 0) {
              this.active = i
              setInterval(() => {
                this.time--
                if (this.time == 0) this.$router.push('/shop')
              }, 1000)
            } else {
              this.$message.error(res.msg)
            }
          })
      }
    },
    //获取方案
    getCase() {
      this.$axios.post(this.$api.site.getCase).then((res) => {
        if (res.code == 0) {
          console.log(res)
          this.caseList = res.result
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    changeStatus(val) {
      this.showImgLibrary = val
    },
    getImg(val) {
      if (val.length) this.logo = val[0].path
      this.showImgLibrary = !1
    }
  }
}
</script>

<style lang="less" scoped>
.el-container {
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  .create-shop {
    width: 950px;
    background: #fff;
    .product {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      .product-item {
        margin-right: 15px;
        margin-bottom: 10px;
        border: 1px solid #f2f2f2;
        width: 210px;
        height: 240px;
        overflow: hidden;
        .top {
          width: 100%;
          text-align: center;
          min-height: 130px;
          padding-top: 32px;
          color: #fff;
          .title {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 10px;
          }
          .desc {
            width: 100%;
            font-size: 12px;
            line-height: 20px;
          }
        }
        .btm {
          width: 100%;
          text-align: center;
          margin-top: 24px;
          .desc {
            width: 100%;
            font-size: 12px;
            margin-bottom: 10px;
          }
        }
      }
    }
    .shop-list {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .shop-list-item {
        width: 240px;
        height: 320px;
        border: 1px solid #f2f1f1;
        list-style: none;
        margin: 0 24px 24px 0;
        text-align: center;
        padding-top: 24px;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .ttile {
          font-weight: 700;
          font-size: 20px;
        }
        .func {
          margin: 32px 0 48px 0;
          width: 100%;
          height: 56px;
        }
        .price {
          font-size: 18px;
          line-height: 24px;
        }
        .now-price {
          padding: 0 8px;
          line-height: 20px;
          height: 20px;
          border: 1px solid red;
          color: red;
          font-size: 12px;
          margin-bottom: 25px;
        }
      }
    }
    .shop-info {
      margin-top: 20px;
      padding: 0 100px;
      .name,
      .shop-logo {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        p {
          width: 75px;
          font-size: 15px;
        }
        .el-input {
          flex: 1;
        }
      }
      .show-img {
        width: 80px;
        margin-bottom: 20px;
        img {
          width: 80px;
          height: 80px;
          border: 1px solid #f4f4f4;
        }
      }
    }
    .success {
      line-height: 250px;
      text-align: center;
    }
  }
}
</style>
